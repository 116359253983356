.reels-welcome-body {
    position: relative;
    width: 1431px;
    height: 790px;
    background: url('../../../../public/assets/images/style/roll-welcome.png') center center no-repeat;
    background-size: contain;
    pointer-events: none;

    &__greeting-start-text {
       
        color: #32faf5;
        width: max-content;
    }

    &__greeting-text {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #32faf5;
        width: max-content;
    }

    &__wrap {
        font-family: var(--font-main);
        font-size: 32px;
        color: #fff;
        line-height: 50px;
        position: absolute;
        z-index: 3;
        top: 25%;
        left: 50%;
        width: 1200px;
        transform: translateX(-50%);
        text-align: center;
    }

    &__counter-text p {
        display: inline;
    }

    &__counter {
        color: #32faf5;
    }

    &__footer {
        position: absolute;
        z-index: 3;
        top: 86.5%;
        left: 50%;
        width: 400px;
        transform: translateX(-50%);
    }

    &__footer-image {
        width: 100%;
        height: 100%;
    }
}

.machine-greeting-underscore {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
