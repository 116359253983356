.reels-question-body {
    position: relative;
    width: 1431px;
    height: 790px;
    background: url('../../../../public/assets/images/style/roll-welcome.png') center center no-repeat;
    background-size: contain;
    pointer-events: none;

    &__label {
        text-align: center;
        padding-top: 200px;
        font-family: var(--font-main);
        font-size: 25px;
        color: #fff;
        line-height: 50px;
    }

    &__table {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }

    &__table-column {
        display: flex;
        align-items: center;
        margin: 0 20px;
    }

    &__slot-number {
        font-family: var(--font-main);
        font-size: 64px;
        color: #32faf5;
        line-height: 50px;
        padding-right: 30px;
        text-shadow: 0 0 5px #fff, 0 0 8px #fff;
    }
}

.question {
    position: relative;
    width: 490px;
    height: 120px;
    border-radius: 31px;
    background: #7c5bb4;

    font-family: var(--font-main), sans-serif;
    font-size: 34px;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    animation: slideIn 1.5s ease-in-out forwards;

    &:before {
        content: '';
        top: -8px;
        left: -8px;

        position: absolute;
        width: 475px;
        height: 105px;
        border: 15px solid #32faf5;
        filter: blur(10px);
        border-radius: 31px;
    }

    &:after {
        content: '';
        top: -2px;
        left: -3px;

        position: absolute;
        width: 487px;
        height: 115px;
        border: 4px solid #ffffff;
        filter: blur(1px);
        border-radius: 31px;
    }
}

.question[data-is-key-down='true'] {
    background: #ff67cb;
    animation: opacity 0.3s ease-in-out 0s 3;
}

@keyframes opacity {
    0% {
        background: #ff67cb;
    }

    50% {
        background: #7c5bb4;
    }

    100% {
        background: #ff67cb;
    }
}

.animate_content {
    animation: animate 3s ease;
}

@keyframes animate {
    10% {
        transform: scale(1, 0.002);
    }
    35% {
        transform: scale(0.2, 0.002) opacity 1;
    }
    50% {
        transform: scale(0.2, 0.002) opacity 0;
    }
    85% {
        transform: scale(1, 0.002) opacity 1;
    }
    100% {
        transform: scale(1, 1);
    }
}
