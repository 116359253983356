.reels-spin-body {
    &__svg {
    }

    &__run {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        cursor: pointer;

        &[data-is-disabled='true'] {
            pointer-events: none;
            cursor: default;
        }
    }

    &__wrap {
        position: relative;
        width: 1431px;
        height: 790px;
        background: url('../../../../public/assets/images/style/roll.png') center center no-repeat;
        background-size: contain;
        pointer-events: none;

        &:before {
            content: '';
            display: block;
            background: #7c5bb4;
            opacity: 0.9;
            filter: blur(80px);
            position: absolute;
            top: 30px;
            left: 8%;
            right: 8%;
            height: 19%;
            z-index: 2;
        }

        &:after {
            content: '';
            display: block;
            background: #7c5bb4;
            opacity: 0.9;
            filter: blur(80px);
            position: absolute;
            bottom: 30px;
            left: 8%;
            right: 8%;
            height: 19%;
            z-index: 2;
        }

        &[data-is-spinning='true'] .outer-col:nth-of-type(2) .col {
            animation-delay: 0.1s;
        }

        &[data-is-spinning='true'] .outer-col:nth-of-type(3) .col {
            animation-delay: 0.2s;
        }

        &[data-is-spinning='true'] .outer-col:nth-of-type(4) .col {
            animation-delay: 0.3s;
        }

        //TODO: сделать три анимации (разгон, крутилка и торможение)
        &[data-is-spinning='true'] .col {
            animation: scroll 6s cubic-bezier(0.5, 0, 0.5, 1) 1, blur 6s cubic-bezier(0.5, 0, 0.5, 1) 1;
        }
    }

    &__win-frame {
        position: absolute;
        z-index: 3;
        top: 23.5%;
        left: 50%;
        width: 1250px;
        transform: translateX(-50%);
        opacity: 1;

        &[data-is-spin-finish='true'] {
            animation: blinker 0.7s ease-in-out 0s infinite;
        }

        &[data-is-spin-finish='false'] {
            width: 0;
            height: 0;
            overflow: hidden;
        }
    }

    &__win-frame-image {
        width: 100%;
        height: 100%;
    }

    &__window-wrap {
        position: relative;
        overflow: hidden;
        height: calc(100% - 108px);
        top: 50%;
        transform: translateY(-50%);
    }

    &__window {
        width: calc(100% - 110px);
        height: 982px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: row;
    }

    &__header {
        position: absolute;
        z-index: 3;
        top: 65px;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 470px;
        height: 311px;
    }

    &__header-banner-default,
    &__header-banner-win-back,
    &__header-banner-win-title {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        opacity: 0.0001;
    }

    &__header-banner-default {
        &[data-is-spin-finish='false'] {
            opacity: 1;
        }
    }

    &__header-banner-win-back {
        &[data-is-spin-finish='true'] {
            opacity: 1;
        }
    }

    &__header-banner-win-title {
        top: 30%;

        &[data-is-spin-finish='true'] {
            animation: blinking 0.7s ease-in-out 0s infinite;
        }
    }

    &__footer {
        position: absolute;
        z-index: 3;
        top: 82%;
        left: 50%;
        width: 1195px;
        transform: translateX(-50%);
    }

    &__footer-banner-back {
        width: 100%;

        &[data-is-spin-finish='true'] {
            opacity: 1;
        }
    }

    &__footer-banner-back,
    &__footer-banner-default,
    &__footer-banner-win-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        opacity: 0.0001;
        align-items: center;
    }

    &__footer-banner-default-title {
        position: absolute;
        display: flex;
        justify-content: center;
        transform: translate(-50%, 10px);
        left: 50%;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;
        padding-top: 1%;
    }

    &__footer-banner-default-congratulation-text {
        text-align: center;
        font-family: var(--font-main);
        font-size: 30px;
        color: #fff;
        flex-wrap: nowrap;
    }

    &__footer-banner-default-star-one,
    &__footer-banner-default-star-two {
        height: 40px;
    }

    &__footer-banner-default-star-one {
        padding-right: 20px;
    }

    &__footer-banner-default-star-two {
        padding-left: 20px;
    }

    &__footer-banner-default-discount-text {
        padding-top: 7%;
        text-align: center;
        font-family: var(--font-main);
        font-size: 21px;
        color: #32FAF5;
    }

    &__footer-banner-win-title,
    &__footer-banner-win-image,
    &__footer-banner-win-text {
        position: absolute;
    }

    &__footer-banner-win-image {
        width: 38%;
        padding-top: 1.5%;
    }

    &__footer-banner-win-text {
        font-family: var(--font-main);
        font-size: 28px;
        color: #fff;
        padding-top: 6.5%;
    }

    &__footer-banner-default,
    &__footer-banner-win-title {
        &[data-is-spin-finish='true'] {
            animation: blinking 0.7s ease-in-out 0s infinite;
        }

        &[data-is-spin-finish='false'] {
            width: 0;
            height: 0;
            overflow: hidden;
        }
    }
}

.outer-col {
    flex: 1;
    width: 300px;
    margin: 0 60px;
    background-color: transparent;
}

.col {
    text-align: center;
    padding: 10px;
    will-change: true;
}

.icon {
    width: 280px;
    height: 315px;
}

@keyframes scroll {
    0% {
        transform: translate3d(0, 0, 0);
    }

    90% {
        transform: translate3d(0, calc(-100% + 900px), 0);
    }

    100% {
        transform: translate3d(0, calc(-100% + 975px), 0);
    }
}

@keyframes blur {
    0% {
        filter: blur(0);
    }

    10% {
        filter: blur(0);
    }

    30% {
        filter: blur(6px);
    }

    60% {
        filter: blur(6px);
    }

    80% {
        filter: blur(0);
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(10deg);
    }

    50% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(10deg);
    }
}

@keyframes blinking {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

@keyframes blinker {
    50% {
        opacity: 0.4;
    }
}
