* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    background: #bba6de;
}

.machine__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 1920px;
    min-height: 1080px;
    background: url('../../../public/assets/images/style/background.jpg') center center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.machine__nail {
    width: 22px;
    height: 22px;
    position: fixed;
    pointer-events: none;
    user-select: none;
    background: #32faf5;
    border-radius: 50%;

    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        background: #565252;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }
}

.machine__nail[data-position='left-top'] {
    top: 45px;
    left: 43px;
}

.machine__nail[data-position='left-bottom'] {
    left: 43px;
    bottom: 45px;
}

.machine__nail[data-position='right-top'] {
    top: 45px;
    right: 43px;
}

.machine__nail[data-position='right-bottom'] {
    right: 43px;
    bottom: 43px;
}

.machine__banner {
    position: absolute;
    z-index: 3;
    top: 65px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 470px;
    height: 311px;
}

.machine__banner-body {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;

    opacity: 0.0001;
}

.machine__banner-body[data-is-visible='true'] {
    opacity: 1;
}
